<template>
    <swipe-list
        v-if="data.length"
        ref="list"
        class="card"
        :class="{ mtdefault: isInstalled, mtdefaultMobile: !isInstalled }"
        :items="data"
        transition-key="id"
        @swipeout:click="itemClick"
    >
        <template slot-scope="{ item }" class="pt16">
            <div>
                <div
                    v-if="passengersStatuses[item.uuid]"
                    class="vip-label label-position"
                >
                    <div>VIP</div>
                </div>
                <div
                    v-if="checkIfPartner(item.partner)"
                    :class="getLabelClass(item)"
                >
                    {{ $t('home.partnerRequest') }}
                    {{ showPrice ? '' : '' }}
                </div>

                <div v-else :class="getLabelClass(item)">
                    {{ $t('home.passengerRequest') }}

                    {{
                        showPrice
                            ? `${currentUser.default_take_price} ${$t(
                                  'global.currency'
                              )}`
                            : ''
                    }}
                </div>
                <div
                    class="time-ago"
                    :class="{ ml16: passengersStatuses[item.uuid] }"
                >
                    {{ $timeAgo(item.created_at, $i18n.locale) }}
                </div>
            </div>
            <div
                class="data-time"
                :class="{ ml16: passengersStatuses[item.uuid] }"
            >
                {{ $formatDate(item.requested_at, $i18n.locale) }}
            </div>
            <div :class="{ ml16: passengersStatuses[item.uuid] }">
                <div class="icon">
                    <img
                        v-if="item.taken_by || $isExpired(item.created_at)"
                        src="@assets/images/icon-route-gray.svg"
                    />
                    <img v-else src="@assets/images/icon-route.svg" />
                </div>
                <div class="route">
                    <div>{{ $shortText(item.from, width) }}</div>
                    <div>{{ $shortText(item.to, width) }}</div>
                </div>
                <div class="icon-phone">
                    <img
                        v-if="item.taken_by || $isExpired(item.created_at)"
                        :src="require('@assets/images/icon-phone.svg')"
                    />
                    <img
                        v-else
                        :src="require('@assets/images/icon_phone_green.svg')"
                    />
                </div>
            </div>
            <attributes-icons :attributes="item.attributes" />
        </template>

        <template slot="left" slot-scope="{ item }">
            <div class="swipeout-action action-panel-left">
                <div @click="itemClick(item)">
                    <img src="@assets/images/icon-add.png" />
                    {{ $t('home.openRequest') }}
                </div>
                <div
                    v-if="
                        item.taken_by === currentUser.carrierUUID ||
                            !item.taken_by
                    "
                    @click="$emit('call', item)"
                >
                    <img
                        v-if="!loading"
                        :src="require('@assets/images/icon-call.svg')"
                    />
                    <img v-else src="@assets/images/loader.gif" />
                    {{ $t('home.call') }}
                </div>
                <div v-else @click="hasBeenTaken(item)">
                    <img
                        v-if="!loading"
                        :src="require('@assets/images/icon-call.svg')"
                    />
                    <img v-else src="@assets/images/loader.gif" />
                    {{ $t('home.call') }}
                </div>
            </div>
        </template>
    </swipe-list>
</template>

<script>
import { mapGetters } from 'vuex';
import { SwipeList, SwipeOut } from 'vue-swipe-actions';
import AttributesIcons from '../elements/AttributesIcons';

export default {
    components: {
        SwipeOut,
        SwipeList,
        AttributesIcons
    },

    data() {
        return {
            width: window.innerWidth
        };
    },

    props: {
        data: {
            type: Array,
            default: []
        },

        loading: {
            type: Boolean,
            default: false
        },

        open: {
            type: Boolean,
            default: false
        },

        showPrice: {
            type: Boolean,
            default: true
        },

        passengersStatuses: {
            default: ()=>[]
        }
    },

    watch: {
        open(e) {
            if (e) {
                this.revealFirstLeft();
            }
        }
    },

    computed: {
        ...mapGetters({
            currentUser: 'auth/currentUser'
        }),

        isInstalled() {
            return window.navigator.standalone === true;
        },

        isIOS() {
            /iPhone|iPad|iPod/i.test(navigator.userAgent);
        }
    },

    methods: {
        getLabelClass(item) {
            const margin = this.passengersStatuses[item.uuid] ? 'ml16' : '';

            if (this.checkIfPartner(item.partner)) {
                return item.taken_by || this.$isExpired(item.created_at)
                    ? `message-gray`
                    : `message-blue`;
            } else {
                return item.taken_by || this.$isExpired(item.created_at)
                    ? `message-gray`
                    : `message-green`;
            }
        },

        checkIfPartner(partner) {
            if (
                partner === '00000000-0000-0000-0000-000000000000' ||
                partner === null ||
                !partner
            ) {
                return false;
            } else {
                return true;
            }
        },

        itemClick(e) {
            this.$router.push(`/app/${e.uuid}`);
        },

        hasBeenTaken(item) {
            this.$emit('hasBeenTaken', item);

            try {
                this.$refs.list.closeActions();
            } catch (error) {
                console.log("can't close");
            }
        },

        revealFirstLeft() {
            try {
                this.$refs.list.revealLeft(0);
            } catch (error) {
                console.log("can't open");
            }

            setTimeout(() => {
                try {
                    this.$refs.list.closeActions(0);
                } catch (error) {
                    console.log("can't close");
                }
            }, 600);
        },

        onResize() {
            this.width = window.innerWidth;
        }
    },

    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        });
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    }
};
</script>

<style scoped>
.label-position {
    left: 0 !important;
    bottom: 0;
    top: 8px;
    transform: rotate(-180deg);
    margin: auto auto;
}
</style>
