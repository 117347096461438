var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mtdefault"},_vm._l((_vm.data),function(item){return _c('div',{key:item.uuid},[_c('md-card',{staticClass:"mt16 p16 pointer custom-hover"},[(_vm.passengersStatuses[item.uuid])?_c('div',{staticClass:"vip-label t-0 label-position"},[_c('div',[_vm._v("VIP")])]):_vm._e(),_c('div',{on:{"click":function($event){return _vm.itemClick(item)}}},[_c('div',{staticClass:"one-row"},[(_vm.checkIfPartner(item.partner))?_c('div',{class:item.taken_by || _vm.$isExpired(item.created_at)
                                ? 'message-gray'
                                : 'message-blue'},[_vm._v(" "+_vm._s(_vm.$t('home.partnerRequest'))+" "+_vm._s(_vm.showPrice ? '' : '')+" ")]):_c('div',{class:item.taken_by || _vm.$isExpired(item.created_at)
                                ? 'message-gray'
                                : 'message-green'},[_vm._v(" "+_vm._s(_vm.$t('home.passengerRequest'))+" "+_vm._s(_vm.showPrice ? ((_vm.currentUser.default_take_price) + " " + (_vm.$t( 'global.currency' ))) : '')+" ")]),_c('div',{staticClass:"time-ago"},[_vm._v(" "+_vm._s(_vm.$timeAgo(item.created_at, _vm.$i18n.locale))+" ")])]),_c('div',{staticClass:"data-time",class:{
                        ml16: _vm.passengersStatuses[item.uuid] && _vm.width < 650
                    }},[_vm._v(" "+_vm._s(_vm.$formatDate(item.requested_at, _vm.$i18n.locale))+" ")]),_c('div',{staticClass:"route-content",class:{
                        ml16: _vm.passengersStatuses[item.uuid] && _vm.width < 650
                    }},[_c('div',{staticClass:"icon"},[(
                                item.taken_by || _vm.$isExpired(item.created_at)
                            )?_c('img',{attrs:{"src":require("@assets/images/icon-route-gray.svg")}}):_c('img',{attrs:{"src":require("@assets/images/icon-route.svg")}})]),_c('div',{staticClass:"route"},[_c('div',[_vm._v(_vm._s(item.from))]),_c('div',[_vm._v(_vm._s(item.to))])]),_c('div',{staticClass:"icon-phone"},[(
                                item.taken_by || _vm.$isExpired(item.created_at)
                            )?_c('img',{attrs:{"src":require('@assets/images/icon-phone.svg')}}):_c('img',{attrs:{"src":require('@assets/images/icon_phone_green.svg')}})])]),_c('attributes-icons',{staticStyle:{"display":"flex","padding-top":"5px"},attrs:{"attributes":item.attributes}})],1)])],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }