<template>
    <div class="attributes">
        <div
            v-for="(value, index) in attributes"
            :key="index"
            :class="{
                'tooltip-icons': value && index > 0,
                none: !value || index === 0
            }"
        >
            <span v-if="value && index >= 1">
                <img
                    class="attributes-icons"
                    :src="
                        require(`@assets/images/${
                            arrayOfIcons[index - 1].icon
                        }`)
                    "
                    alt="info"
                />
                <md-tooltip md-direction="top">
                    <span
                        ng-bind-html="msg | unsafeSpecial"
                        v-html="arrayOfIcons[index - 1].message"
                    />
                </md-tooltip>
            </span>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        attributes: {
            default: [],
            default: Array
        }
    },

    data() {
        return {
            arrayOfIcons: [
                {
                    message: this.$t('global.attributes.consignment'),
                    icon: 'icon_box.png'
                },
                {
                    message: this.$t('global.attributes.pet'),
                    icon: 'icon_pet.png'
                },
                {
                    message: this.$t('global.attributes.wheelchair'),
                    icon: 'icon_wheelchair.png'
                },
                {
                    message: this.$t('global.attributes.otherDay'),
                    icon: 'icon-calendar-plus.png'
                },
                {
                    message: this.$t('global.attributes.returnJourney'),
                    icon: 'icon-return.png'
                },
                {
                    message: this.$t('global.attributes.morning'),
                    icon: 'icon-sun.png'
                },
                {
                    message: this.$t('global.attributes.night'),
                    icon: 'icon-moon.png'
                }
            ]
        };
    }
};
</script>
