<template>
    <div class="justify">
        <b>{{ $t('route.tip') }}:</b>
        {{ tip }}
    </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            tip: ''
        };
    },

    methods: {
        ...mapActions({
            getTip: 'flotea/tip'
        }),

        async tips() {
            this.tip = await this.getTip();
        }
    },

    mounted() {
        this.tips();
    }
};
</script>
