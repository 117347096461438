<template>
    <div class="mtdefault">
        <div v-for="item in data" :key="item.uuid">
            <md-card class="mt16 p16 pointer custom-hover">
                <div
                    v-if="passengersStatuses[item.uuid]"
                    class="vip-label t-0 label-position"
                >
                    <div>VIP</div>
                </div>
                <div @click="itemClick(item)">
                    <div class="one-row">
                        <div
                            v-if="checkIfPartner(item.partner)"
                            :class="
                                item.taken_by || $isExpired(item.created_at)
                                    ? 'message-gray'
                                    : 'message-blue'
                            "
                        >
                            {{ $t('home.partnerRequest') }}
                            {{ showPrice ? '' : '' }}
                        </div>

                        <div
                            v-else
                            :class="
                                item.taken_by || $isExpired(item.created_at)
                                    ? 'message-gray'
                                    : 'message-green'
                            "
                        >
                            {{ $t('home.passengerRequest') }}
                            {{
                                showPrice
                                    ? `${currentUser.default_take_price} ${$t(
                                          'global.currency'
                                      )}`
                                    : ''
                            }}
                        </div>
                        <div class="time-ago">
                            {{ $timeAgo(item.created_at, $i18n.locale) }}
                        </div>
                    </div>
                    <div
                        class="data-time"
                        :class="{
                            ml16: passengersStatuses[item.uuid] && width < 650
                        }"
                    >
                        {{ $formatDate(item.requested_at, $i18n.locale) }}
                    </div>
                    <div
                        class="route-content"
                        :class="{
                            ml16: passengersStatuses[item.uuid] && width < 650
                        }"
                    >
                        <div class="icon">
                            <img
                                v-if="
                                    item.taken_by || $isExpired(item.created_at)
                                "
                                src="@assets/images/icon-route-gray.svg"
                            />
                            <img v-else src="@assets/images/icon-route.svg" />
                        </div>
                        <div class="route">
                            <div>{{ item.from }}</div>
                            <div>{{ item.to }}</div>
                        </div>
                        <div class="icon-phone">
                            <img
                                v-if="
                                    item.taken_by || $isExpired(item.created_at)
                                "
                                :src="require('@assets/images/icon-phone.svg')"
                            />
                            <img
                                v-else
                                :src="
                                    require('@assets/images/icon_phone_green.svg')
                                "
                            />
                        </div>
                    </div>
                    <attributes-icons
                        style="display:flex; padding-top:5px"
                        :attributes="item.attributes"
                    />
                </div>
            </md-card>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SwipeList, SwipeOut } from 'vue-swipe-actions';
import AttributesIcons from '../elements/AttributesIcons';

export default {
    components: {
        SwipeOut,
        SwipeList,
        AttributesIcons
    },

    props: {
        data: {
            type: Array,
            default: []
        },
        loading: {
            type: Boolean,
            default: false
        },

        showPrice: {
            type: Boolean,
            default: true
        },

        passengersStatuses: {
            default:()=>[]
        }
    },

    data() {
        return {
            width: window.innerWidth
        };
    },

    computed: {
        ...mapGetters({
            currentUser: 'auth/currentUser'
        })
    },

    methods: {
        checkIfPartner(partner) {
            if (
                partner === '00000000-0000-0000-0000-000000000000' ||
                partner === null ||
                !partner
            ) {
                return false;
            } else {
                return true;
            }
        },

        itemClick(e) {
            this.$router.push(`/app/${e.uuid}`);
        },

        onResize() {
            this.width = window.innerWidth;
        }
    },

    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        });
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    }
};
</script>
<style scoped>
@media only screen and (max-width: 650px) {
    .label-position {
        transform: rotate(-180deg);
        left: 0 !important;
        bottom: 0;
        top: 8px;
        transform: rotate(-180deg);
        margin: auto auto;
    }
}
</style>
