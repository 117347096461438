var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data.length)?_c('swipe-list',{ref:"list",staticClass:"card",class:{ mtdefault: _vm.isInstalled, mtdefaultMobile: !_vm.isInstalled },attrs:{"items":_vm.data,"transition-key":"id"},on:{"swipeout:click":_vm.itemClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('div',[(_vm.passengersStatuses[item.uuid])?_c('div',{staticClass:"vip-label label-position"},[_c('div',[_vm._v("VIP")])]):_vm._e(),(_vm.checkIfPartner(item.partner))?_c('div',{class:_vm.getLabelClass(item)},[_vm._v(" "+_vm._s(_vm.$t('home.partnerRequest'))+" "+_vm._s(_vm.showPrice ? '' : '')+" ")]):_c('div',{class:_vm.getLabelClass(item)},[_vm._v(" "+_vm._s(_vm.$t('home.passengerRequest'))+" "+_vm._s(_vm.showPrice ? ((_vm.currentUser.default_take_price) + " " + (_vm.$t( 'global.currency' ))) : '')+" ")]),_c('div',{staticClass:"time-ago",class:{ ml16: _vm.passengersStatuses[item.uuid] }},[_vm._v(" "+_vm._s(_vm.$timeAgo(item.created_at, _vm.$i18n.locale))+" ")])]),_c('div',{staticClass:"data-time",class:{ ml16: _vm.passengersStatuses[item.uuid] }},[_vm._v(" "+_vm._s(_vm.$formatDate(item.requested_at, _vm.$i18n.locale))+" ")]),_c('div',{class:{ ml16: _vm.passengersStatuses[item.uuid] }},[_c('div',{staticClass:"icon"},[(item.taken_by || _vm.$isExpired(item.created_at))?_c('img',{attrs:{"src":require("@assets/images/icon-route-gray.svg")}}):_c('img',{attrs:{"src":require("@assets/images/icon-route.svg")}})]),_c('div',{staticClass:"route"},[_c('div',[_vm._v(_vm._s(_vm.$shortText(item.from, _vm.width)))]),_c('div',[_vm._v(_vm._s(_vm.$shortText(item.to, _vm.width)))])]),_c('div',{staticClass:"icon-phone"},[(item.taken_by || _vm.$isExpired(item.created_at))?_c('img',{attrs:{"src":require('@assets/images/icon-phone.svg')}}):_c('img',{attrs:{"src":require('@assets/images/icon_phone_green.svg')}})])]),_c('attributes-icons',{attrs:{"attributes":item.attributes}})]}},{key:"left",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"swipeout-action action-panel-left"},[_c('div',{on:{"click":function($event){return _vm.itemClick(item)}}},[_c('img',{attrs:{"src":require("@assets/images/icon-add.png")}}),_vm._v(" "+_vm._s(_vm.$t('home.openRequest'))+" ")]),(
                    item.taken_by === _vm.currentUser.carrierUUID ||
                        !item.taken_by
                )?_c('div',{on:{"click":function($event){return _vm.$emit('call', item)}}},[(!_vm.loading)?_c('img',{attrs:{"src":require('@assets/images/icon-call.svg')}}):_c('img',{attrs:{"src":require("@assets/images/loader.gif")}}),_vm._v(" "+_vm._s(_vm.$t('home.call'))+" ")]):_c('div',{on:{"click":function($event){return _vm.hasBeenTaken(item)}}},[(!_vm.loading)?_c('img',{attrs:{"src":require('@assets/images/icon-call.svg')}}):_c('img',{attrs:{"src":require("@assets/images/loader.gif")}}),_vm._v(" "+_vm._s(_vm.$t('home.call'))+" ")])])]}}],null,false,1660854604)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }